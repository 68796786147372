import {StyledText} from "../../../../components/StyledText";
import './styles.css';
import {LinkButton} from "../../../../components/LinkButton";
import {ArrowIcon} from "../../../../components/icons/ArrowIcon";
import Image1 from './img/image1.png';
import Image2 from './img/image2.png';
import Image3 from './img/image3.png';
import Image4 from './img/image4.png';
import Image5 from './img/image5.png';
import Image6 from './img/image6.png';
import {useEffect, useMemo, useState} from "react";

const items = [{
    image1: Image2,
    image2: Image1,
}, {
    image1: Image4,
    image2: Image3,
}, {
    image1: Image6,
    image2: Image5,
}]

const GroupsSection = () => {

    const [selected, setSelected] = useState(0);

    const images = useMemo(() => {
        return items[selected];
    }, [selected]);

    const handleClick = (type: 'prev' | 'next') => {
        if (selected === 0 && type === 'prev') setSelected(items.length - 1);
        else if (selected === items.length - 1 && type === 'next') setSelected(0);
        else if (type === 'next') setSelected(selected + 1);
        else setSelected(selected - 1);
    }


    useEffect(() => {
        const timer = setInterval(() => {
            handleClick('next')
        }, 15000);
        return () => clearInterval(timer);
    });

    return <section className={"groups-section"}>
        <div className={"groups-container wide-container"}>
            <div className={"groups-col"}>
                <StyledText variant={"h3"} className={"groups-subtitle"}>
                    Входные группы
                </StyledText>
                <StyledText variant={"h2"} className={"groups-title"}>
                    Новый уровень входных групп
                </StyledText>
                <p className={"groups-description"}>
                    В ЖК Зеленоград Ривьера во входных зонах панорамные окна, что создает эффект простора и свободы и
                    обеспечивает максимум света уже на пороге вашего жилого пространства. Общайтесь с соседями и
                    встречайтесь с гостями в обстановке комфорта и эстетики.
                </p>
            </div>
            <div className={"groups-col"}>
                <div className={"groups-images"}>
                    <img src={images.image1} className={"groups-image"}/>
                    <img src={images.image2} className={"groups-image"}/>
                </div>
                <div className={"groups-images-controls"}>
                    <LinkButton
                        icon={<ArrowIcon style={{transform: 'rotate(180deg)'}}/>} reversed box
                        onClick={() => handleClick('prev')}/>
                    <LinkButton icon={<ArrowIcon/>} onClick={() => handleClick('next')} box/>
                </div>
            </div>
        </div>
    </section>;
}

export default GroupsSection;