import Image1 from './img/image1.png';
import Image2 from './img/image2.png';
import Image3 from './img/image3.png';
import Image4 from './img/image4.png';
import BackgroundGif from './img/background-gif.gif';

import './styles.css';
import {LinkButton} from "../../../../components/LinkButton";
import {ArrowIcon} from "../../../../components/icons/ArrowIcon";
import {useEffect, useMemo, useState} from "react";

const slides = [{
    title: 'Новый уровень жизни',
    subtitle: 'Подземный паркинг',
    text: 'Большой подземный паркинг под всей площадью застройки. Забудьте о бесконечных поисках места для парковки, когда вы возвращаетесь с работы вечером.|Вне зависимости от класса и габаритов вашего автомобиля вы можете поставить его в подземный паркинг.',
    image: Image1,
}, {
    title: 'Новый уровень жизни',
    subtitle: 'Детский сад',
    text: 'Большой подземный паркинг под всей площадью застройки. Забудьте о бесконечных поисках места для парковки, когда вы возвращаетесь с работы вечером.|Вне зависимости от класса и габаритов вашего автомобиля вы можете поставить его в подземный паркинг.',
    image: Image2,
},
    {
        title: 'Новый уровень жизни',
        subtitle: 'Эко площадки',
        text: 'Большой подземный паркинг под всей площадью застройки. Забудьте о бесконечных поисках места для парковки, когда вы возвращаетесь с работы вечером.|Вне зависимости от класса и габаритов вашего автомобиля вы можете поставить его в подземный паркинг.',
        image: Image3,
    },
    {
        title: 'Новый уровень жизни',
        subtitle: 'Входные группы',
        text: 'Большой подземный паркинг под всей площадью застройки. Забудьте о бесконечных поисках места для парковки, когда вы возвращаетесь с работы вечером.|Вне зависимости от класса и габаритов вашего автомобиля вы можете поставить его в подземный паркинг.',
        image: Image4,
    }]

const SlidesSection = () => {
    const [activeSlide, setActiveSlide] = useState(0);

    const handleClick = (type: 'prev' | 'next') => {
        if (activeSlide === 0 && type === 'prev') setActiveSlide(slides.length - 1);
        else if (activeSlide === slides.length - 1 && type === 'next') setActiveSlide(0);
        else if (type === 'next') setActiveSlide(activeSlide + 1);
        else setActiveSlide(activeSlide - 1);
    }

    const current = useMemo(() => {
        return slides[activeSlide];
    }, [activeSlide]);

    useEffect(() => {
        const timer = setInterval(() => {
            handleClick('next')
        }, 15000);
        return () => clearInterval(timer);
    })

    return <section id={"#gallery"} className={"slides-section"}>
        <div className={"wide-container slides-container"}>
            <div className={"slides-section-wrapper"}>
                <div className={"slides-section-wrapper-col"}>
                    <div className={"slides-section-video"} style={{
                        backgroundImage: `url(${BackgroundGif})`,
                    }}/>
                </div>
                <div className={"slides-section-wrapper-col slides-section-slider"}>
                    <div className={"slides-section-description"}>
                        <h2 className={"slides-section-description-title"}>
                            {current.title}
                        </h2>
                        <h3 className={"slides-section-description-subtitle"}>
                            {current.subtitle}
                        </h3>
                        {current.text.split('|').map((i) => (
                            <p className={"slides-section-description-text"} key={i}>{i}</p>))}
                    </div>
                    <div className={"slides-section-image"} style={{
                        backgroundImage: `url(${current.image})`,
                    }}/>
                    <div className={"slides-section-controls"}>
                        <div className={"progress-bar"}>
                            <div className={"progress-bar-slide"} style={{
                                width: `${((activeSlide + 1) / slides.length * 100)}%`,
                            }}/>
                        </div>
                        <div className={"progress-bar-controls"}>
                            <LinkButton onClick={() => handleClick('prev')}
                                        icon={<ArrowIcon style={{transform: 'rotate(180deg)'}}/>} reversed box/>
                            <LinkButton onClick={() => handleClick('next')} icon={<ArrowIcon/>} box/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default SlidesSection;