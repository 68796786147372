import {StyledText} from "../../components/StyledText";
import BackgroundImage from './img/background.png';
import icon from './img/icon.png';
import {
    AboutProject,
    Header,
    VariantsSection,
    Footer,
    NewsSection,
    StepsSection,
    InfraSection,
    GroupsSection,
    LawsSection,
    SlidesSection,
} from "./entites";
import './styles.css';
import React from "react";

const MainPage = () => {
    return <div className="main-page">
        <section className={"introduction-container"} id="introduction" style={{background: `url(${BackgroundImage})`}}>
            <Header/>
            <div className={"container"}>
                <StyledText variant={"h2"} className={"introduction-text"}>
                    Новый уровень жизни
                </StyledText>
            </div>
            <div className={"introduction-footer container"}>
                <div className={"introduction-footer-col introduction-footer-col--white"}>
                    <img src={icon} height="25px" width="25px"/>
                    <StyledText variant="h3" style={{color: "#BC9566"}}>Москва, Зеленоград, к 226с1</StyledText>
                </div>
                <div className={"introduction-footer-col introduction-footer-col--gold"}>
                    <StyledText variant="h3" style={{color: "white"}}>срок сдачи — 2026 год</StyledText>
                </div>
            </div>
        </section>
        <AboutProject/>
        <SlidesSection/>
        <LawsSection/>
        <VariantsSection/>
        <GroupsSection/>
        <InfraSection/>
        <StepsSection/>
        <NewsSection/>
        <Footer/>

    </div>

}

export default MainPage;