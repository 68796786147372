import {StyledText} from "../../../../components/StyledText";
import './styles.css';
import Image from './img/image.png';
import Image2 from './img/image2.png';
import Image3 from './img/image3.png';
import Image4 from './img/image4.png';
import {ArrowIcon} from "../../../../components/icons/ArrowIcon";
import {LinkButton} from "../../../../components/LinkButton";

const items = [{
    date: '16.08.2023',
    title: 'Кэшбэк от «Яндекс Плюс» за покупку квартиры в ЖК Зеленоград Ривьера',
    image: Image,
},{
    date: '16.08.2023',
    title: 'Первый куб бетона залит в фундамент ЖК Зеленоград Ривьера',
    image: Image2,
},{
    date: '16.08.2023',
    title: 'Скидка 1% в честь Дня строителя в ЖК Зеленоград Ривьера',
    image: Image3,
},{
    date: '16.08.2023',
    title: 'Старт продаж в ЖК Зеленоград Ривьера',
    image: Image4,
}]
const NewsSection = () => {
    return <section id={"news"} className={"news-section"}>
        <div className={"wide-container steps-container"}>
            <StyledText variant={"h3"} className={"news-title"}>Новости</StyledText>
            <div className={"news-list"}>
                {items.map((i) => (
                    <a style={{backgroundImage: `url(${i.image})`}} className={"news-item"} key={i.title}>
                        <span className={"news-item-date"}>{i.date}</span>
                        <span className={"news-item-title"}>{i.title}</span>
                    </a>))}
            </div>
            <LinkButton className={"news-btn"} onClick={() => console.log('4124')} icon={<ArrowIcon/>} box>
                Все новости
            </LinkButton>
        </div>
    </section>;
};

export default NewsSection;