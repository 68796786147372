import {StyledText} from "../StyledText";
import React, {useState} from "react";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {LinkButton} from "../LinkButton";
import {ArrowIcon} from "../icons/ArrowIcon";
import BgImage from './img/bg-image.png';

const menuItems = [{
    title: 'Главная страница',
    href: '#introduction',
}, {
    title: 'О проекте',
    href: '#about-project',
}, {
    title: 'Подбор квартир',
    href: '#floors',
}, {
    title: 'Галерея',
    href: '#galery',
}, {
    title: 'Ход строительства',
    href: '#steps',
}, {
    title: 'Инфраструктура района',
    href: '#infra',
}, {
    title: 'Ипотека',
    href: '#laws',
}, {
    title: 'Документы',
    href: '#docs',
}, {
    title: 'Новости',
    href: '#news',
}, {
    title: 'Контакты',
    href: '#contacts',
}];

export const TopMenu = () => {
    const [isOpen, setOpen] = useState(false);

    const handleBtnClick = (payload?: boolean) => {
        setOpen(payload ? payload : !isOpen);

        // Если меню открыто
        if (!isOpen) {
            document.body.classList.add('not-overflow');
            document.getElementById('main-header')?.classList.add('is-open');
        }
        else {
            document.body.classList.remove('not-overflow');
            document.getElementById('main-header')?.classList.remove('is-open');

        }

    }

    return <div>
        <button className={"main-header-menu-btn"} onClick={() => handleBtnClick()}>
            <StyledText variant={"h3"} className={clsx("title-side", {
                "is-open": isOpen,
            })}>
                <span>Меню</span>
            </StyledText>
        </button>
        <div className={clsx("main-header-menu-container", {
            'is-open': isOpen
        })}>
            <div className={"wide-container main-header-menu-section"}>
            <div className={"main-header-menu-container-col main-header-menu-container-list-container"}>
                <div className={"main-header-menu-list"}>
                    {menuItems.map((item) => <a className={"main-header-menu-list-item"} href={item.href}
                                                   key={item.title} onClick={() => handleBtnClick(false)}>{item.title}</a>)}
                </div>
                <LinkButton Component={Link} to={"21341"} className={"main-header-menu-list-link"} icon={<ArrowIcon/>} box>
                    Скачать буклет
                </LinkButton>
            </div>

            <div className={"main-header-menu-container-col main-header-menu-container-col-img"} style={{
                backgroundImage: `url(${BgImage})`
            }}>
            </div>

            </div>
        </div>
    </div>;
}