import {ArrowIcon} from "../../../../components/icons/ArrowIcon";
import {LinkButton} from "../../../../components/LinkButton";
import {Button2} from "../../../../components/Button";
import Image from './img/room.png';
import MiniImage from './img/mini-image.png';
import {useState} from "react";
import {StyledText} from "../../../../components/StyledText";
import clsx from 'clsx';
import './styles.css';

const items = [{
    image: Image,
    square: '39.6 м2',
    price: 5842000,
}, {
    image: Image,
    square: '40.6 м2',
    price: 3842000,
}, {
    image: Image,
    square: '39.6 м2',
    price: 4842000,
}, {
    image: Image,
    square: '39.6 м2',
    price: 5842000,
}, {
    image: Image,
    square: '40.6 м2',
    price: 3842000,
}, {
    image: Image,
    square: '39.6 м2',
    price: 4842000,
}]
const VariantsSection = () => {

    const [activeLayout, setActiveLayout] = useState(0);
    const [activeRoomCount, setActiveRoomCount] = useState([1]);

    const handleClickRoom = (rooms: number) => {
        // Удалить таб
        if (activeRoomCount.includes(rooms) && activeRoomCount.length !== 1) setActiveRoomCount(activeRoomCount.filter((i) => i !== rooms));
        else setActiveRoomCount([...activeRoomCount, rooms]); // Добавить таб
    }
    return <section id={"floors"} className={"variant-section"}>
        <div className={"wide-container variant-container"}>
            <div className={"variant-container-col variant-container-layouts"}>
                <StyledText variant={"h3"} className={"variant-container-layouts-title"}>Планировки</StyledText>
                <img src={MiniImage} className={"variant-container-mini-image"}/>
                <div className={"variant-container-layouts-list"}>
                    <button onClick={() => setActiveLayout(0)} className={clsx({
                        'is-active': activeLayout === 0,
                    }, "variant-container-layouts-tab")}>
                        <span className={"variant-container-layout-tab-title"}>Корпус А</span>
                        <span className={"variant-container-layout-tab-subtitle"}>14 этажей</span>
                    </button>
                    <button onClick={() => setActiveLayout(1)} className={clsx({
                        'is-active': activeLayout === 1,
                    }, "variant-container-layouts-tab")}>
                        <span className={"variant-container-layout-tab-title"}>Корпус А</span>
                        <span className={"variant-container-layout-tab-subtitle"}>14 этажей</span>
                    </button>
                    <button onClick={() => setActiveLayout(2)} className={clsx({
                        'is-active': activeLayout === 2,
                    }, "variant-container-layouts-tab")}>
                        <span className={"variant-container-layout-tab-title"}>Корпус А</span>
                        <span className={"variant-container-layout-tab-subtitle"}>14 этажей</span>
                    </button>
                    <button onClick={() => setActiveLayout(3)} className={clsx({
                        'is-active': activeLayout === 3,
                    }, "variant-container-layouts-tab")}>
                        <span className={"variant-container-layout-tab-title"}>Корпус А</span>
                        <span className={"variant-container-layout-tab-subtitle"}>14 этажей</span>
                    </button>
                </div>
            </div>
            <div className={"variant-container-col variant-container-content"}>
                <div className={"variant-container-controls"}>
                    <div className={"variant-container-controls-col variant-container-tabs"}>
                        <Button2 className={clsx({
                            'is-active': activeRoomCount.includes(0)
                        }, "variant-container-tabs-btn")} onClick={() => handleClickRoom(0)}>
                            {activeRoomCount.includes(0) ? <>Студия</> : <>Ст</>}
                        </Button2>
                        <Button2 className={clsx({
                            'is-active': activeRoomCount.includes(1)
                        }, "variant-container-tabs-btn")} onClick={() => handleClickRoom(1)}>
                            {activeRoomCount.includes(1) ? <>1 комната</> : <>1</>}
                        </Button2>
                        <Button2 className={clsx({
                            'is-active': activeRoomCount.includes(2)
                        }, "variant-container-tabs-btn")} onClick={() => handleClickRoom(2)}>
                            {activeRoomCount.includes(2) ? <>2 комнаты</> : <>2</>}
                        </Button2>
                        <Button2 className={clsx({
                            'is-active': activeRoomCount.includes(3)
                        }, "variant-container-tabs-btn")} onClick={() => handleClickRoom(3)}>
                            {activeRoomCount.includes(3) ? <>3 комнаты</> : <>3</>}
                        </Button2>
                    </div>
                    <div className={"variant-container-controls-col"}>
                        <LinkButton onClick={() => console.log('4124')} icon={<ArrowIcon/>} box>
                            Все планировки
                        </LinkButton>
                    </div>
                </div>
                <div className={"variant-container-room-list"}>
                    {items.slice(0, 6).map((i) => (
                        <article className={"variant-container-room-list-item"} key={i.image}>
                            <img alt={"Квартира"} src={i.image} className={"variant-container-room-list-item-image"}/>
                            <div className={"variant-container-room-list-item-field"}>
                                <p className={"variant-container-room-list-item-title"}>
                                    Площадь
                                </p>
                                <p className={"variant-container-room-list-item-subtitle"}>
                                    {i.square}
                                </p>
                            </div>
                            <div className={"variant-container-room-list-item-field"}>
                                <p className={"variant-container-room-list-item-title"}>
                                    Стоимость
                                </p>
                                <p className={"variant-container-room-list-item-subtitle"}>
                                    {i.price.toLocaleString()} ₽
                                </p>
                            </div>
                        </article>))}
                </div>
            </div>
        </div>
    </section>;
};

export default VariantsSection;