import clsx from "clsx";
import {ReactNode} from "react";
import './styles.css';

type ButtonProps = {
    onClick?: (..._args: unknown[]) => void;
    className?: string;
    children: ReactNode;
    active?: boolean;
}

export const Button = (props: ButtonProps) => {
    const { onClick, className, children, active, ...rest} = props;
    return <button className={clsx({
        'btn': true,
        'btn-active': active,
    }, className)} onClick={onClick} {...rest} >
        {children}
    </button>
    
}

type Button2Props = {
    onClick?: (..._args: unknown[]) => void;
    className?: string;
    children: ReactNode;
    active?: boolean;
}

export const Button2 = (props: Button2Props) => {
    const { onClick, className, children, active, ...rest} = props;
    return <button className={clsx({
        'btn2': true,
        'btn-active': active,
    }, className)} onClick={onClick} {...rest} >
        {children}
    </button>
    
}

