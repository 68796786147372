import {StyledText} from "../../../../components/StyledText";
import './styles.css';
import Image from './img/image.png';
import Image2 from './img/image2.png';
import Image3 from './img/image3.png';
import Image4 from './img/image4.png';
import {ArrowIcon} from "../../../../components/icons/ArrowIcon";
import {LinkButton} from "../../../../components/LinkButton";

const items = [{
    date: '16.08.2023',
    title: 'Август 2023',
    image: Image,
}, {
    date: '16.08.2023',
    title: 'Август 2023',
    image: Image2,
}, {
    date: '16.08.2023',
    title: 'Август 2023',
    image: Image3,
}, {
    date: '16.08.2023',
    title: 'Август 2023',
    image: Image4,
}]
const StepsSection = () => {
    return <section id={"steps"} className={"steps-section"}>
        <div className={"wide-container steps-container"}>
            <StyledText variant={"h3"} className={"steps-title"}>Ход строительства</StyledText>
            <div className={"steps-list"}>
                {items.map((i) => (
                    <a style={{backgroundImage: `url(${i.image})`}} className={"steps-item"} key={i.title}>
                        <span className={"steps-item-date"}>{i.date}</span>
                        <span className={"steps-item-title"}>{i.title}</span>
                    </a>))}
            </div>
            <LinkButton className={"steps-btn"} onClick={() => console.log('4124')} icon={<ArrowIcon/>} box>
                Смотреть все этапы
            </LinkButton>
        </div>
    </section>;
};

export default StepsSection;