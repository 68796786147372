import {StyledText} from "../../../../components/StyledText";
import {LogoIcon} from "./img/LogoIcon";
import './styles.css';
import {TopMenu} from "../../../../components/TopMenu";

const Header = () => {
    return <header id={"main-header"} className="header main-header container">
        <div className={"header-col"}>
            <TopMenu />
        </div>
        <div className={"header-col logo"}>
            <LogoIcon/>
        </div>
        <div className={"header-col"}>
            <StyledText variant={"h3"}>
                +7 (499) 214-2-214
            </StyledText>
        </div>
    </header>
};


export default Header;