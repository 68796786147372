import {ChangeEvent, ReactNode, useEffect, useMemo, useState} from "react";
import './styles.css';

type RangeInputProps = {
    defaultValue?: number;
    max: number;
    min: number;
    afterText?: string;
    roundStep?: number;
    label?: string | ReactNode;
    inputLabel?: string;
    callback: (payload: number) => void;
}
export const RangeInput = (props: RangeInputProps) => {
    const {defaultValue, min, max, inputLabel, afterText, label, roundStep = 1, callback, ...rest} = props;

    const beautifyValue = useMemo(() => {
        return ((defaultValue || min) - (defaultValue || min) % roundStep)?.toLocaleString() + (inputLabel || '') || '';
    }, [defaultValue, min, max, inputLabel]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const payload = +(e.target.value.replace(/\D/g,'').replace(/\s/g, ''));

        if (Number.isNaN(payload)) return;

        if (payload > max || payload < min) return;
        callback(payload);
    }

    const handleSlide = (e: ChangeEvent<HTMLInputElement>) => {
        const payload = +(e.target.value.replace(/\D/g,'').replace(/\s/g, ''));

        if (Number.isNaN(payload)) return;
        if (payload > max || payload < min) return;

        callback(payload);
    }

    return <div className={"range-input"}>
        <label className={"range-input-label"}>{label}</label>
        <div className={"range-input-field"}>
            <div className={"range-input-input"}>
                <input className={"range-input-input-text"} type={"text"} max={max} min={min} value={beautifyValue}
                       onChange={handleChange}/>
                {afterText && <span className={"range-input-input-after-text"}>{afterText}</span>}
            </div>
            <input className={"range-input-slider"} value={defaultValue} type={"range"} min={min} max={max}
                   onChange={handleSlide} /></div>
    </div>
};