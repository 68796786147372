import {CSSProperties, ReactNode} from "react";
import clsx from "clsx";
import './styles.css';

type StyledTextProps = {
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'a' | 'button' | 'price'; // typescript синтаксис, говорим, что может быть только строки 'h1', 'h2', 'h3'
    className?: string; // если синтаксис вида parameter?: any -> (?:) - то параметр не обязателен и может отсутствовать
    // Виды параметров берутся из typescript.
    children: ReactNode;
    Component?: 'span' | 'div' | 'p' | 'button' | React.ComponentType<any>;
    onClick?: (...args: unknown[]) => void;
    style?: CSSProperties;
    /**
     это то, что вложится в наш тэг. Например,
     <StyledText>
     kwej3hr32
     </StyledText>,
     где kwej3hr32 - children (в виде строки).
     children - может быть как строкой, так и любым другим объектом или даже компоненто.
     <StyledText>
     <SomeComponent>
     <AnotherComponent />
     </SomeComponent>
     </StyledText>

     Если мы хотим явно указать, что у компонента нет дочерних (то есть вложить в него ничего нельзя),
     мы пишем children: void; (void - параметр обязательно отсутствует)
     */
}

export const StyledText = (props: StyledTextProps) => {
    const {variant, className, Component = 'p', children, ...rest} = props;

    return <Component className={clsx(variant, className)} {...rest}>{children}</Component>
}