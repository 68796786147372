import {StyledText} from "../../../../components/StyledText";
import './styles.css';
import Walked from './img/walked.svg';
import Car from './img/car.svg';
import MapImage from './img/map.png';

const InfraSection = () => {
    return <section id={"infra"} className={"infra-section"}>
        <div className={"infra-container wide-container"}>
            <div className={"infra-col"}>
                <StyledText variant={"h3"} className={"infra-subtitle"}>
                    Инфраструктура
                </StyledText>
                <div className={"infra-title-col"}>
                    <StyledText variant={"h2"} className={"infra-title"}>
                        Новый уровень комфорта
                    </StyledText>
                    <ul className={"infra-title-metro-list"}>
                        <li className={"infra-title-metro-list-item"}>
                            Спорт и фитнес
                        </li>
                        <li className={"infra-title-metro-list-item"}>
                            Магазины
                        </li>
                        <li className={"infra-title-metro-list-item"}>
                            Школы
                        </li>
                        <li className={"infra-title-metro-list-item"}>
                            Кафе и рестораны
                        </li>
                        <li className={"infra-title-metro-list-item"}>
                            Поликлиники
                        </li>
                    </ul>
                </div>
                <div className={"infra-road"}>
                    <div className={"infra-road-field"}>
                        <div className={"infra-road-field-title"}>
                            <img src={Walked} className={"infra-road-icon"} />пешком
                        </div>
                        <div className={"infra-road-field-list"}>
                            <div className={"infra-road-field-list-item"}>
                                <div className={"infra-road-field-item-title"}>
                                    Парк Победы
                                </div>
                                <div className={"infra-road-field-item-subtitle"}>
                                    15 минут
                                </div>
                            </div>
                            <div className={"infra-road-field-list-item"}>
                                <div className={"infra-road-field-item-title"}>
                                    Поликлиника
                                </div>
                                <div className={"infra-road-field-item-subtitle"}>
                                    3 минуты
                                </div>
                            </div>
                            <div className={"infra-road-field-list-item"}>
                                <div className={"infra-road-field-item-title"}>
                                    Березовая роща
                                </div>
                                <div className={"infra-road-field-item-subtitle"}>
                                    10 минут
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"infra-road-field"}>
                        <div className={"infra-road-field-title"}>
                            <img src={Car} className={"infra-road-icon"} />на машине
                        </div>
                        <div className={"infra-road-field-list"}>
                            <div className={"infra-road-field-list-item"}>
                                <div className={"infra-road-field-item-title"}>
                                    Ж/Д Станция Крюково
                                </div>
                                <div className={"infra-road-field-item-subtitle"}>
                                    15 минут
                                </div>
                            </div>
                            <div className={"infra-road-field-list-item"}>
                                <div className={"infra-road-field-item-title"}>
                                    м. «Речной вокзал»
                                </div>
                                <div className={"infra-road-field-item-subtitle"}>
                                    35 минут
                                </div>
                            </div>
                            <div className={"infra-road-field-list-item"}>
                                <div className={"infra-road-field-item-title"}>
                                    м. «Пятницкое шоссе»
                                </div>
                                <div className={"infra-road-field-item-subtitle"}>
                                    35 минут
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"infra-col"}>
                <img className={"infra-map"} src={MapImage} />
            </div>
        </div>
    </section>;
}

export default InfraSection;