import {CSSProperties} from "react";

type ArrowIconProps = {
    color?: string;
    className?: string;
    style?: CSSProperties;
}
type Cross = {
    color?: string;
    className?: string;
    
}


export const ArrowIcon = (props: ArrowIconProps) => {
    const {className, color = '#000', style} = props;
    return <svg className={className} width="19" height="12" viewBox="0 0 19 12" fill="none"
                xmlns="http://www.w3.org/2000/svg" style={style}>
        <g id="Group 5">
            <path id="Vector" d="M0 6H17" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
            <path id="Vector_2" d="M12 1L17 6L12 11" stroke={color} strokeWidth="1.5"/>
        </g>
    </svg>;
}
export const CrossIcon = (props: Cross) => {
    const {className, color} = props;
    return <svg className={className} width="30" height="20" viewBox="15 0 30 30" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <g id="Group 5">
            <path id="Vector" d="11 6L12 1L17 M12" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
            <path id="Vector_2" d="M12 1L17 6L12 11" stroke={color} strokeWidth="1.5"/>
        </g>
    </svg>;
}