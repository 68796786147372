import Mkb from './img/mkb.png';
import {StyledText} from "../../../../components/StyledText";
import {RangeInput} from "../../../../components/RangeInput";
import {useMemo, useState} from "react";
import './styles.css';
import {Button2} from "../../../../components/Button";
import {ArrowIcon} from "../../../../components/icons/ArrowIcon";

const banks = [{
    logo: Mkb,
    percentFrom: '2%',
    monthlyFrom: '57 040',
    firstPay: '15%',
    years: '30 лет',
    tags: ['Семейная ипотека']
}, {
    logo: Mkb,
    percentFrom: '5%',
    monthlyFrom: '57 041',
    firstPay: '15%',
    years: '30 лет',
    tags: ['Семейная ипотека']
}, {
    logo: Mkb,
    percentFrom: '3%',
    monthlyFrom: '53 042',
    firstPay: '15%',
    years: '30 лет',
    tags: ['Семейная ипотека']
}, {
    logo: Mkb,
    percentFrom: '22%',
    monthlyFrom: '50 043',
    firstPay: '15%',
    years: '30 лет',
    tags: ['Семейная ипотека']
}, {
    logo: Mkb,
    percentFrom: '10%',
    monthlyFrom: '57 044',
    firstPay: '15%',
    years: '30 лет',
    tags: ['Семейная ипотека']
}, {
    logo: Mkb,
    percentFrom: '15%',
    monthlyFrom: '57 045',
    firstPay: '15%',
    years: '30 лет',
    tags: ['Семейная ипотека']
}];
const LawsSection = () => {
    const [formState, setFormState] = useState({
        cost: 3_400_000,
        firstPay: 1_700_000,
        percentTo: 20,
        years: 30,
    });

    // Изменение первого платежа
    const changeCost = (payload: number) => {
        const minFirstPay = payload * 0.99 <= formState.firstPay ? payload * 0.99 : payload * 0.15 >= formState.firstPay ? payload * 0.15 : formState.firstPay;

        setFormState({
            ...formState,
            cost: payload,
            firstPay: minFirstPay,
        })
    }

    // Изменение первоначального взноса
    const changeFirstPay = (payload: number) => {
        setFormState({
            ...formState,
            firstPay: payload
        });
    }

    // Вычисляем процент первого взноса от общей стоимости жилья
    const firstPayPercent = useMemo(() => {
        return `${Math.round(formState.firstPay / formState.cost * 100)}%`;
    }, [formState]);

    return <section className={"laws-section"} id={"laws"}>
        <div className={"wide-container laws-section-container"}>
            <form className={"laws-section-form"}>
                <StyledText variant={"h4"} className={"laws-section-form-title"}>Рассчитайте ипотеку</StyledText>
                <div className={"laws-section-form-field"}>
                    <RangeInput
                        min={3_400_000}
                        max={20_000_000}
                        callback={changeCost}
                        label={"Стоимость квартиры, руб."}
                        roundStep={100_000}
                        defaultValue={Math.round(formState.cost)}
                    />
                </div>
                <div className={"laws-section-form-field"}>
                    <RangeInput
                        min={Math.round(formState.cost * 0.15)}
                        max={Math.round(formState.cost * 0.99)}
                        callback={changeFirstPay}
                        label={"Первоначальный взнос, руб."}
                        roundStep={100_000}
                        defaultValue={Math.round(formState.firstPay)}
                        afterText={firstPayPercent}
                    />
                </div>
                <div className={"laws-section-form-field"}>
                    <RangeInput
                        max={30}
                        min={1}
                        defaultValue={formState.percentTo}
                        callback={(payload) => {
                            setFormState({...formState, percentTo: payload});
                        }}
                        label={"Ставка кредита"}
                        inputLabel={"%"}
                    />
                </div>
                <div className={"laws-section-form-field"}>
                    <RangeInput
                        max={30}
                        min={1}
                        defaultValue={formState.years}
                        callback={(payload) => {
                            setFormState({...formState, years: payload});
                        }}
                        label={"Срок кредита"}
                        inputLabel={" лет"}
                    />
                </div>
                <div className={"laws-section-form-btn-container"}>
                    <Button2 className={"laws-section-form-btn"} onClick={() => {
                    }}>Подобрать <ArrowIcon color={"#FFF"} className={"laws-section-form-btn-arrow-icon"}/></Button2>
                </div>
            </form>
            <section className={"laws-section-banks"}>
                <div className={"laws-section-list"}>
                    {banks.map((i) => (
                        <article className={"laws-section-bank"} key={i.monthlyFrom}>
                            <img src={i.logo} className={"laws-section-bank-logo"} alt={"МКБ"}/>
                            <div className={"laws-section-bank-percents"}>
                                <p className={"laws-section-bank-subtitle"}>Ставка кредита</p>
                                <p className={"laws-section-bank-percent-from"}>от {i.percentFrom}</p>
                                <p className={"laws-section-bank-monthly-from"}>от {i.monthlyFrom} Р / мес</p>
                            </div>
                            <div className={"laws-section-bank-percents"}>
                                <p className={"laws-section-bank-subtitle"}>Первоначальный взнос</p>
                                <p className={"laws-section-bank-monthly-from"}>от {i.firstPay}</p>
                            </div>
                            <div className={"laws-section-bank-percents"}>
                                <p className={"laws-section-bank-subtitle"}>Срок кредита</p>
                                <p className={"laws-section-bank-monthly-from"}>до {i.years}</p>
                            </div>
                            <div className={"laws-section-bank-tags"}>
                                {i.tags.map((tag) => (<div key={tag} className={"laws-section-bank-tag"}>{tag}</div>))}
                            </div>
                        </article>
                    ))}
                </div>
            </section>
        </div>
    </section>;
};

export default LawsSection;