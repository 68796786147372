import {ReactNode} from "react";
import './styles.css';
import clsx from "clsx";

type LinkButtonProps = {
    onClick?: () => void;
    icon: ReactNode;
    children?: ReactNode;
    box?: boolean;
    reversed?: boolean;
    className?: string;
    Component?: 'span' | 'div' | 'p' | 'button' | React.ComponentType<any>;
    [key: string]: any;
}

export const LinkButton = (props: LinkButtonProps) => {
    const { onClick, icon, children, Component = 'button', reversed, box, className } = props;
    return <Component onClick={onClick} className={clsx({
        "btn-link": true,
        "btn-link--reversed": reversed,
    }, className)}>
        {children && <span className="btn-link-text">{children}</span>}
        {/* так как img не поддерживает before псевдоэлемент */}
        <div className="btn-link-img">
            {box && <div className="btn-link-box" />}
            {icon}
        </div>
    </Component>
}