import {StyledText} from "../../../../components/StyledText";
import {LogoIcon} from "../Header/img/LogoIcon";
import icon from './img/icon.svg';
import BgImage from './img/bg-image.png';
import {ArrowIcon} from "../../../../components/icons/ArrowIcon";
import './styles.css';

const Footer = () => {
    return <footer className={"footer"} style={{
        backgroundImage: `url(${BgImage})`,
    }} id={"docs"}>
        <div className={"wide-container footer-container"}>
            <div className={"footer-green"}>
                <StyledText variant={"h1"} className={"footer-title"}>
                    Новый уровень жизни
                </StyledText>
                <div className={"footer-contacts"}>
                    <LogoIcon className={"footer-logo"}/>
                    <p className={"footer-phone"}>+7 (499) 214-2-214</p>
                    <p className={"footer-schedule"}>Ежедневно с 9.00 до 21.00</p>
                    <p className={"footer-mail"}>info@andreevka.life</p>
                </div>
                <div className={"footer-address"}>
                    <img src={icon} height="25px" width="25px"/>
                    Москва, Зеленоград, к 226с1
                </div>
            </div>
            <div className={"footer-copyright"} id={"contacts"}>
                <div className={"footer-copyright-left"}>
                    <p className={"footer-copyright-left-title"}>
                        © Зеленоград Ривьера, 2023
                    </p>
                    <p className={"footer-copyright-left-subtitle"}>
                        ООО "Специализированный застройщик "КапиталИнвест"
                    </p>
                </div>
                <a className={"footer-documentation"}>
                    Документацию <ArrowIcon className={"footer-documentation-arrow"}/>
                </a>
            </div>
        </div>
    </footer>
};

export default Footer;