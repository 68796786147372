import {StyledText} from "../../../../components/StyledText";
import {ArrowIcon} from "../../../../components/icons/ArrowIcon";
import {LinkButton} from "../../../../components/LinkButton";

import BgImage from './img/bg-image.png';
import './styles.css';

const AboutProject = () => {
    return <section id={"#about-project"} className={"about-project-container"} style={{backgroundImage: `url(${BgImage})`}}>
        <div className={"container"}>
            <div className={"about-project-wrapper"}>
            <StyledText variant={"h3"} className={"about-project-subtitle"}>жилой комплекс Зеленоград Ривьера</StyledText>
            <StyledText variant={"h2"} className={"about-project-title"}>
                Новый уровень жизни
            </StyledText>
            <div className={"about-project-text"}>
                <StyledText variant={"p"}>ЖК «Андреевка Life» — это симбиоз удачного расположения, хорошей экологии,
                    новейшей архитектуры, полностью развитой инфраструктуры, а также атмосферы добрососедства — всё это
                    создает прекрасное место для тех, кто ценит единение с природой, но в то же время не хочет терять
                    ритм города.</StyledText>
                <StyledText variant={"p"}>Комплекс состоит из 8 монолитных домов с яркими фасадами, уютными дворами и
                    широким выбором квартир различных площадей и планировок. Природа, тишина и спокойствие — это именно
                    то, что ожидает Вас в жилом комплексе «Андреевка Life».</StyledText>
            </div>
            <div>
                <LinkButton onClick={() => console.log('4124')} icon={<ArrowIcon/>} box>
                    О проекте
                </LinkButton>
            </div>
            </div>
        </div>
    </section>;
}

export default AboutProject;
